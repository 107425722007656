export const HEADERS = {
  STORE: 'Store',
  URL: 'X-Forwarded-Url',
  AUTH: 'Authorization',
  COOKIE: 'Cookie',
  SEGMENT: 'X-Customer-Segment', // Varnish -> hash key
  // GROUP: 'X-Next-Customer-Group', // Varnish -> cache if GQL POST with "GENERAL" code
  CACHE: 'X-Varnish-Status',
  IP: 'X-Forwarded-For', // 'X-Real-IP' and 'X-Forwarded-For' are identical for now (TODO: check once the IP is forwarded correctly)
  UA: 'User-Agent',
  API: 'x-api-key', // used for RECOMMENDATION ENGINE
  MISS: 'X-Miss-Forwarded-Url', // missing xForwardedUrl
  CT: 'Content-Type',
  NOT_FOUND: 'NOT_FOUND', // Header sent from middleware to display 404 instead of trying to render and crashing with notFound()
  APP_IDENTIFIER: 'X-App-Identifier',
  APP_VERSION: 'X-App-Version',
  APP_PLATFORM: 'X-App-Platform',
} as const

export type RequestHeadersType = typeof HEADERS[keyof typeof HEADERS]
