import { stripIgnoredCharacters } from 'graphql'

import { HEADERS } from '@/common/types/header-types'
import { getStoreCode, IS_DEBUG_MODE } from '@/common/utils'
import { consoleLog } from '../utils/console'

type FetchInitType = {
  storeCode?: string
  url?: string
  userAgent?: string
  token?: string
  segment?: string
  // groupCode?: string // TODO-GROUP
  cookie?: string
  hasForwardedUrl?: boolean
}

export type GraphQLResponse<T> = Promise<
  T & {
    errors?: Array<{
      message: string
      extensions?: {
        category?: string
      }
    }>
  }
>

/**
 * Identifier mostly for client requests for visibility of caching
 */
export const API_VERSION = '0.0.4' // TODO: use commit hash

/**
 * Recommended Security and Performance Max: 2048 CHARACTERS
 *
 * TODO: Currently using 4k chars since graphql requests are not optimized to be smaller
 *
 * Note: Because GraphQL documents strings may be quite long for complex operations,
 * the query parameters may exceed the limits that servers, browsers or CDNs impose on URL lengths.
 * In order to make sure this limit is not exceeded, use POST instead of GET but log it in order to be able to debug it
 */
const MAX_URL_LEN = 4_096

/**
 * AbortSignal - fetch timeout in miliseconds - 10s
 */
export const FETCH_TIMEOUT = 10_000

/**
 * HTTP Methods
 */
export const HTTP_METHOD = {
  GET: 'GET',
  POST: 'POST',
} as const
export type HttpMethodType = typeof HTTP_METHOD[keyof typeof HTTP_METHOD]

/**
 * Queries that should never be cached - must match GQL Document from generated graphql.tsx
 */
export const POST_QUERIES = [
  'query Cart',
  'query Customer',
  'query Xsearch(',
  // 'query Category',
  // Account: add all account specific queries below:
  'query GiftCardAccount(',
]

/**
 * @throws
 * @param query
 * @param variables
 */
export const getGraphQLQuery = (query: string, variables?: unknown): string => {
  const gqlParams = new URLSearchParams({
    query: stripIgnoredCharacters(query),
  })

  if (variables) {
    gqlParams.set('variables', JSON.stringify(variables))
  }

  return gqlParams.toString()
}

/**
 * Set only non-empty request headers
 *
 * @param headers - The target Headers (new or existing)
 * @param props - override with
 */
export const extendHeaders = (
  headers: Headers,
  {
    storeCode,
    url,
    userAgent,
    token,
    segment,
    // groupCode, // TODO-GROUP
    cookie,
    hasForwardedUrl,
  }: FetchInitType,
): Headers => {
  const requestHeaders = {
    [HEADERS.URL]: url,
    [HEADERS.STORE]: storeCode ?? getStoreCode(url),
    [HEADERS.UA]: userAgent,
    [HEADERS.AUTH]: token ? `Bearer ${token}` : '',
    [HEADERS.SEGMENT]: segment,
    // [HEADERS.GROUP]: groupCode === 'General' ? 'GENERAL' : '', // TODO-GROUP
    [HEADERS.COOKIE]: cookie,
    [HEADERS.MISS]: hasForwardedUrl ? '' : '1',
  } as const

  for (const [key, value] of Object.entries(requestHeaders)) {
    if (value) {
      headers.set(key, value)
    }
  }

  return headers
}

type FetchGraphQLProps = {
  url: URL | string
  method: HttpMethodType
  headers: Headers
  body?: string
}

/**
 * @throws
 */
export const nextFetch = async ({
  url,
  method,
  headers,
  body,
}: FetchGraphQLProps): Promise<any> => {
  const start = Date.now()
  const href = new URL(url.toString(), 'http://localhost:3000')

  // Note: Next.js adds _rsc param to requests
  href.searchParams.delete('_rsc')

  const response = await fetch(href.toString(), {
    method,
    headers,
    body,
  })
  const time = Date.now() - start
  const shouldAbort = time > FETCH_TIMEOUT
  const shouldUsePost = url.toString().length > MAX_URL_LEN

  if (IS_DEBUG_MODE || shouldUsePost || shouldAbort) {
    consoleLog(
      getApiType(url),
      getMessage({
        url,
        body,
        method,
        time,
        shouldAbort,
        shouldUsePost,
      }),
    )
  }

  if (!response || response?.status !== 200) {
    if (response?.status === 499) {
      consoleLog(
        getApiType(url),
        getMessage({
          url,
          body,
          method,
          time,
          shouldAbort,
          shouldUsePost,
        }),
      )
      return null
    } else {
      throw new Error('Invalid Response Status', {
        cause: {
          status: response?.status ?? 500,
        },
      })
    }
  }

  const contentType = response.headers.get(HEADERS.CT)

  if (!contentType || !contentType.includes('application/json')) {
    throw new Error('Invalid Response Type - required JSON', {
      cause: {
        status: response.status,
      },
    })
  }

  const data = await response.json()

  return data
}

/**
 * @param headers - stringify & trim for logging
 */
export const stringifyHeaders = (headers?: Headers): string => {
  let headersString = ''

  if (!headers) {
    return ''
  }

  for (const [key, value] of headers.entries()) {
    if (shouldLogHeader(key.toLowerCase())) {
      headersString += `${key}: ${value}; `
    }
  }

  return headersString.trim() + '...'
}

const shouldLogHeader = (key: string) => {
  if (key === 'user-agent' || key === 'store') {
    return true
  }

  // Identifiers - TODO: trace id header
  if (
    key === 'x-forwarded-url' ||
    key === 'x-api-key' ||
    key === 'authorization' ||
    key === 'segment'
  ) {
    return true
  }

  // Mobile App
  if (
    key === 'x-app-identifier' ||
    key === 'x-app-platform' ||
    key === 'x-app-version'
  ) {
    return true
  }

  return false
}

export const getUrl = (url: URL | string, body?: string) => {
  const max = 42
  const href = url.toString()
  const { pathname, search } = new URL(url, 'http:localhost') // for client fetch the origin is missing

  if (pathname.startsWith('/api/graphql') || pathname.startsWith('/graphql')) {
    if (body) {
      const q = Math.max(
        body.indexOf('query ') + 6,
        body.indexOf('mutation ') + 9,
      )
      const i = body.indexOf(' {')
      const j = body.indexOf('(')
      const k = Math.min(i !== -1 ? i : max, j !== -1 ? j : max)

      return body.slice(q, k)
    }

    if (search.startsWith('?query=query+')) {
      const q = 13
      const i = search.indexOf('%7B')
      const j = search.indexOf('%28%24')
      const k = Math.min(i !== -1 ? i : max, j !== -1 ? j : max)

      return search.slice(q, k)
    }
  }

  if (href.startsWith('http')) {
    const len = `${pathname}`.length
    return `${pathname.slice(0, max)}${len > max ? '...' : ''}`
  }

  const len = `${href}`.length
  return `${href.slice(0, max)}${len > max ? '...' : ''}`
}

const getChars = (url: URL | string) => {
  const len = url.toString().length

  if (len > MAX_URL_LEN) {
    return `[${url.toString().length}char]`
  }

  return ''
}

const getApiType = (url: URL | string) => {
  const { pathname } = new URL(url, 'http:localhost') // for client fetch the origin is missing

  if (pathname.includes('/graphql')) {
    return 'GraphQL'
  }

  return 'REST'
}

const getMessage = ({
  url,
  body,
  method,
  time,
  shouldAbort,
  shouldUsePost,
}: {
  url: URL | string
  body?: string
  method: string
  time: number
  shouldAbort: boolean
  shouldUsePost: boolean
}) => {
  const warnTime = shouldAbort ? '][!' : ''
  const warnLength = shouldUsePost ? `][${getChars(url)}` : ''
  const formattedTime = `${time}ms${warnTime}`
  const formattedUrl = `${getUrl(url, body)}${warnLength}`

  return `[${method}] [${formattedTime}] [${formattedUrl}]`
}
