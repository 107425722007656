import {
  getCookie,
  removeCookie,
  COOKIE,
  setCookie,
} from '@/common/utils/cookie-utils'

const YEAR_IN_SECONDS = 31_536_000

export const getCustomerToken = () => {
  const token = getCookie(COOKIE.NEXT_TOKEN) ?? getCookie(COOKIE.CUSTOMER_TOKEN)

  return token
}

export const removeCustomerToken = () => {
  removeCookie(COOKIE.NEXT_TOKEN)
  removeCookie(COOKIE.CUSTOMER_TOKEN)
}

export const setCustomerToken = (token: string) => {
  const expires = new Date(Date.now() + YEAR_IN_SECONDS)

  setCookie(COOKIE.NEXT_TOKEN, token, { path: '/', expires })
  setCookie(COOKIE.CUSTOMER_TOKEN, token, { path: '/', expires })
}
