import { Domain } from '../types'
import { StoreCodeType } from '../types/store-code'
import { STORE_CODES } from './get-store-code'

/**
 * HP URL
 * UA, RU -> Adds a slash to the URL
 */
export const getHomepageUrl = (
  storeCode: StoreCodeType,
  url: string,
): string => {
  if (storeCode === STORE_CODES.ua) {
    return url.endsWith('/') ? url : `${url}/`
  }

  return url
}

/**
 * Blog URL
 * UA -> Add `/uk` at the end of the url
 */
export const getUaBlogUrl = (storeCode: StoreCodeType, url: string): string => {
  if (storeCode === STORE_CODES.ua) {
    return url.endsWith('/') ? `${url}uk` : `${url}/uk`
  }

  return url
}

/**
 * Blog URL
 * - UA, RU -> Remove locale from url
 */
export const getUaRuBlogUrl = (storeCode: StoreCodeType, url: URL): string => {
  if (storeCode === STORE_CODES.ua || storeCode === STORE_CODES.ru) {
    const pathname = getPathnameWithoutLocale(url.pathname, storeCode)

    return `${url.origin}${pathname}${url.search}${url.hash}`
  }

  return url.toString()
}

export const isDualCurrency = (storeCode: StoreCodeType) =>
  storeCode === STORE_CODES.ba || storeCode === STORE_CODES.rs

/*
 * we are using gymbeamsk as main currency only when in dual currency store
 * because gymbeamsk is using formatting that we want to show to customers
 */
export const getDualCurrencyStoreCode = (storeCode: StoreCodeType) => {
  return isDualCurrency(storeCode) ? STORE_CODES.sk : storeCode
}

export const GENERAL_MARKET_THRESHOLD = 300

export const isGeneralMarket = (storeCode: StoreCodeType) =>
  storeCode === STORE_CODES[Domain.COM]

export const hasVatShippingMessage = (storeCode: string): boolean => {
  return storeCode === STORE_CODES.de || storeCode === STORE_CODES.at
}

/*
 * Stores excluded from standard delivery price calculation and display
 * we are not allowing free return policy for these stores
 */
export const STORES_EXCLUDED_FROM_FREE_RETURN = [
  'gymbeamba' as StoreCodeType,
  'gymbeamrs' as StoreCodeType,
  'ua' as StoreCodeType,
  'ru' as StoreCodeType,
] as const

/**
 * Pathname without UA or RU locale
 */
export const getPathnameWithoutLocale = (
  pathname: string,
  storeCode: StoreCodeType,
) => {
  if (storeCode === STORE_CODES.ua || storeCode === STORE_CODES.ru) {
    if (pathname === `/${storeCode}`) {
      return '/'
    }

    if (pathname.startsWith(`/${storeCode}/`)) {
      return pathname.replace(`/${storeCode}`, '')
    }
  }

  return pathname
}
