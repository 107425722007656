import {
  COOKIE,
  getCookie,
  removeCookie,
  setCookie,
} from '@/common/utils/cookie-utils'

export const getCustomerGroupCode = () => {
  const groupCode = getCookie(COOKIE.CUSTOMER_GROUP)

  return groupCode
}

export const removeCustomerGroupCode = () => {
  removeCookie(COOKIE.CUSTOMER_GROUP)
}

export const setCustomerGroupCode = (groupCode: string) => {
  setCookie(COOKIE.CUSTOMER_GROUP, groupCode, { path: '/' })
}
