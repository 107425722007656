import { StoreCodeType } from '../types'
import { getDualCurrencyStoreCode, isDualCurrency } from './store.exceptions'
import { CurrencyEnum, CurrencyType } from '@/api'

export type PriceFormatSettings = {
  price: number
  storeCode: StoreCodeType
  currency?: CurrencyType | string | null
  currencyFormat?: string
  dualCurrency?: boolean
}

type PriceFormatParams = {
  storeCode: StoreCodeType
  dualCurrency?: {
    rate: number
    format: string
  }
  price: {
    value: number
    currency?: CurrencyEnum | string
  }
}

export function formatPrice({
  currency,
  price,
  storeCode,
  currencyFormat,
  dualCurrency = false,
}: PriceFormatSettings) {
  const { thousandDelimiter, fractionDelimiter, fractionDigits } =
    getFormatOptionsByStoreCode(storeCode, dualCurrency)

  const [roundedPrice, fractionPrice = ''] = price
    .toFixed(fractionDigits)
    .split('.')

  const formattedPrice = [
    (+roundedPrice).toLocaleString('en-US').replace(/,/g, thousandDelimiter),
    ...(fractionPrice ? [fractionPrice] : []),
  ].join(fractionDelimiter)

  if (currencyFormat) {
    return currencyFormat.replace('%s', formattedPrice)
  }

  const formattedCurrency = getFormattedCurrency(storeCode, currency)

  return getCurrencyFormatPrice(
    storeCode,
    formattedPrice,
    dualCurrency,
    formattedCurrency,
  )
}

function getFormatOptionsByStoreCode(
  storeCode: StoreCodeType,
  dualCurrency: boolean,
): {
  thousandDelimiter: string
  fractionDelimiter: string
  fractionDigits: number
} {
  if (dualCurrency) {
    return {
      thousandDelimiter: ',',
      fractionDelimiter: '.',
      fractionDigits: 2,
    }
  }

  switch (storeCode) {
    case 'gymbeamcom':
      return {
        thousandDelimiter: ',',
        fractionDelimiter: '.',
        fractionDigits: 2,
      }
    case 'gymbeampl':
    case 'gymbeambg':
    case 'ua':
    case 'ru':
      return {
        thousandDelimiter: '',
        fractionDelimiter: ',',
        fractionDigits: 2,
      }
    case 'gymbeamhu':
      return {
        thousandDelimiter: '\xa0',
        fractionDelimiter: ',',
        fractionDigits: 0,
      }
    case 'gymbeamro':
    case 'gymbeamsi':
    case 'gymbeamba':
    case 'gymbeamrs':
    case 'gymbeamgr':
    case 'gymbeamit':
    case 'gymbeamhr':
    case 'gymbeamde':
      return {
        thousandDelimiter: '.',
        fractionDelimiter: ',',
        fractionDigits: 2,
      }
    case 'gymbeamat':
      return {
        thousandDelimiter: '.',
        fractionDelimiter: ',',
        fractionDigits: 2,
      }
    case 'gymbeamsk':
    case 'gymbeamcz':
    default:
      return {
        thousandDelimiter: '\xa0',
        fractionDelimiter: ',',
        fractionDigits: 2,
      }
  }
}

//this will be later removed
function getFormattedCurrency(
  storeCode: StoreCodeType,
  currency?: CurrencyType | string | null,
): string | undefined | null {
  switch (currency) {
    case 'EUR': {
      switch (storeCode) {
        case 'gymbeamhr':
          return 'EUR'
        default:
          return '€'
      }
    }
    case 'CZK':
      return 'Kč'
    case 'PLN':
      return 'zł'
    case 'HUF':
      return 'Ft'
    case 'RON':
      return 'Lei'
    case 'BAM':
      return 'KM'
    case 'RSD':
      return 'RSD'
    case 'BGN':
      return 'лв.'
    case 'UAH':
      return 'грн'
    default:
      return currency
  }
}

function getCurrencyFormatPrice(
  storeCode: StoreCodeType,
  price: string,
  dualCurrency: boolean,
  formattedCurrency?: string | null,
): string {
  if (dualCurrency) {
    return `${price} ${formattedCurrency}`
  }

  switch (storeCode) {
    case 'gymbeamcom':
      return `${formattedCurrency}${price}`
    case 'gymbeampl':
    case 'gymbeamhu':
    case 'gymbeamro':
    case 'gymbeamba':
    case 'gymbeambg':
      return `${price}${formattedCurrency}`
    case 'gymbeamrs':
    case 'gymbeamsk':
    case 'gymbeamcz':
    case 'gymbeamsi':
    case 'gymbeamgr':
    case 'gymbeamit':
    case 'gymbeamhr':
    case 'gymbeamde':
    case 'gymbeamat':
    case 'ua':
    case 'ru':
    default:
      return `${price} ${formattedCurrency}`
  }
}

export function roundPrice(price: number): string {
  return price.toFixed(2)
}

/**
 * Formats the price based on the provided parameters.
 *
 * @param {PriceFormatParams} params - The parameters for formatting the price.
 * @returns {object} The formatted main and local price.
 *
 * mainPrice is returned using store code, but when store is using dual currency such as
 * gymbeamba or gymbeamrs then mainPrice is always returned in EUR
 */
export function getFormattedPrices({
  storeCode,
  dualCurrency,
  price,
}: PriceFormatParams) {
  const mainPrice = formatPrice({
    currency: price.currency,
    storeCode: getDualCurrencyStoreCode(storeCode),
    price: price.value,
  })

  const localPrice =
    dualCurrency?.rate && dualCurrency?.format
      ? formatPrice({
          storeCode: storeCode,
          price: isDualCurrency(storeCode)
            ? price.value * (dualCurrency?.rate ?? 1)
            : price.value,
          currencyFormat: dualCurrency.format,
          dualCurrency: true,
        })
      : undefined

  return {
    mainPrice,
    localPrice,
  }
}
