import { COOKIE, getCookie } from '@/common/utils/cookie-utils'
import { clientFetch } from '../fetch.client'

export const fetchGuestToken = async () => {
  const persistentQuote = getCookie(COOKIE.PERSISTENT_QUOTE)

  if (persistentQuote) {
    return persistentQuote
  }

  return clientFetch<string>({
    url: '/rest/V1/guest-carts-session',
    method: 'POST',
  })
}
