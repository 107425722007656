import { isServer } from '@tanstack/react-query'

import { IS_DEV } from '@/utils'
import {
  API_VERSION,
  POST_QUERIES,
  GraphQLResponse,
  getGraphQLQuery,
  nextFetch,
  stringifyHeaders,
  extendHeaders,
  getUrl,
} from '../api.utils'
import { getCustomerSegment, getCustomerToken } from '@/providers/auth/utils'
// import { getCustomerGroupCode } from '@/providers/auth/utils/customer-group-code' // TODO-GROUP
import { consoleError } from '@/common/utils/console'
import { HEADERS } from '@/common/types/header-types'

/**
 * @param query
 * @param variables
 * @param headers
 */
export const gqlClientFetcher =
  <TData, TVariables>(
    query: string,
    variables?: TVariables,
    requestHeaders?: HeadersInit,
  ): (() => GraphQLResponse<TData>) =>
  async () => {
    if (isServer) {
      throw new Error('isServer')
    }

    const now = new Date()
    const startFetching = now.valueOf()
    const timestamp = now.toISOString()

    let headers: Headers | undefined
    let isPost = false
    let pathname = IS_DEV ? '/api/graphql' : '/graphql'
    let body: string | undefined = undefined
    let search = ''
    let response: any = undefined

    try {
      const userAgent = window?.navigator?.userAgent ?? ''

      if (!userAgent) {
        throw new Error('Missing userAgent')
      }

      headers = extendHeaders(new Headers(requestHeaders), {
        url: window.location.href,
        token: getCustomerToken(),
        segment: getCustomerSegment(),
        // groupCode: getCustomerGroupCode(), // TODO-GROUP
        userAgent,
      })

      isPost =
        query.includes('mutation ') ||
        POST_QUERIES.some((postQuery) => query.includes(postQuery))

      if (isPost) {
        headers.set(HEADERS.CT, 'application/json')
        body = JSON.stringify({ query, variables })

        if (query.includes('query Xsearch(')) {
          search = `?search=tr`
        }
      } else {
        search = `?${getGraphQLQuery(query, variables)}`
      }

      response = await nextFetch({
        url: `${pathname}${search}`,
        method: isPost ? 'POST' : 'GET',
        headers,
        body,
      })

      if (!response) {
        throw new Error('No Response')
      }

      if (response?.errors) {
        throw new Error('GraphQL Errors', {
          cause: response?.errors?.[0],
        })
      }

      return response?.data
    } catch (error) {
      consoleError('next-fetcher.client', {
        timestamp,
        message: 'GraphQL Fetch Error',
        source: 'gqlClientFetcher',
        version: API_VERSION,
        context: {
          duration: `${Date.now() - startFetching}ms`,
          method: isPost ? 'POST' : 'GET',
          url: getUrl(`${pathname}${search}`, JSON.stringify(body)),
          variables,
          headers: stringifyHeaders(headers),
        },
        error: {
          message: error?.message ?? error,
          cause: error?.cause,
        },
      })
    }

    return {
      ...response?.data,
      errors: response?.errors,
    }
  }
