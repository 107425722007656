import { arePricesDifferentInVariants } from '.'
import { CurrencyEnum, ProductStockStatus } from '../api/graphql'
import {
  ConfigurableVariantItem,
  ProductBySKUs,
  ProductListItem,
  RecommendedProduct,
  RecommendedProducts,
  RecommendedProductsInput,
} from '../types/product-types'
import { PriceRange } from '@/components/product-list-item/product-data-item-types'
import { clientFetch } from '../api/rest/fetch.client'
import { consoleLog } from './console'

export enum ListItemProductType {
  GiftCard = 'giftcard',
  Configurable = 'configurable',
}

export const getPositionOfProduct = (
  recommendedProducts: RecommendedProduct[],
  variants: ConfigurableVariantItem[],
): number | undefined => {
  if (!recommendedProducts?.length || !variants?.length) {
    consoleLog('getPositionOfProduct: Invalid input', {
      recommendedProducts,
      variants,
    })
    return undefined
  }

  const prodSimpleIds = variants.map((variant) => variant.product.id)

  const position = recommendedProducts.findIndex(
    (product) =>
      product?.productIdSimple &&
      prodSimpleIds.includes(product.productIdSimple.toString()),
  )

  if (position === -1) {
    consoleLog('getPositionOfProduct: No matching product found', {
      prodSimpleIds,
      recommendedProducts,
    })
    return undefined
  }

  return position
}

export const processRecommendedItems = (
  limit: number,
  products: ProductListItem[],
  recommendedProducts: RecommendedProduct[],
): ProductListItem[] => {
  const recommendedOrderMap = new Map(
    recommendedProducts.map((prod, index) => [prod.productIdConfig, index]),
  )

  return products
    .filter((prod) => prod.isSaleable && prod.longTermUnavailable === 0)
    .sort((a, b) => {
      const orderA = recommendedOrderMap.get(a.id)
      const orderB = recommendedOrderMap.get(b.id)

      // If both products are not in recommended map, maintain original order
      if (orderA === undefined && orderB === undefined) return 0
      // Put products without recommended order at the end
      if (orderA === undefined) return 1
      if (orderB === undefined) return -1
      return orderA - orderB
    })
    .slice(0, limit)
}

const getProductListItemType = (
  type: string,
): ListItemProductType | undefined => {
  if (
    type === ListItemProductType.Configurable ||
    type === ListItemProductType.GiftCard
  ) {
    return type
  }

  return undefined
}

function getPriceRangeOfConfigurable(
  configurable: ProductBySKUs,
  currency?: CurrencyEnum,
): PriceRange {
  return {
    isPriceRange: configurable.configurable_variants
      ? configurable.configurable_variants.length >= 2 &&
        arePricesDifferentInVariants(
          configurable.configurable_variants.map(
            (variant) => variant.product.price.final_price,
          ),
        )
      : false,
    price: configurable.price.min_price,
    currency: currency || 'EUR',
  }
}

function getPriceRangeOfGiftCard(
  giftCard: any,
  currency?: CurrencyEnum,
): PriceRange {
  return {
    currency: currency || 'EUR',
    price: giftCard.price.min_price,
    isPriceRange: true,
  }
}

export function formatProductToListItem(
  product: ProductBySKUs,
  storeConfigCurrency?: CurrencyEnum,
) {
  const priceRange =
    product.type === ListItemProductType.Configurable
      ? getPriceRangeOfConfigurable(product, storeConfigCurrency)
      : getPriceRangeOfGiftCard(product, storeConfigCurrency)

  const isProductSingleVariant = product.configurable_variants?.length === 1
  const singleVariantSku = isProductSingleVariant
    ? product.configurable_variants?.[0].product.sku
    : product.sku
  const ratingSummary = Number(product?.['rating-result'] ?? '0')
  const stockStatus = product?.is_saleable
    ? ProductStockStatus.InStock
    : ProductStockStatus.OutOfStock
  const thumbnail = {
    url: product?.image.url,
    label: product?.image.alt,
  }
  const enabled = product?.long_term_unavailable === '0'
  const reviewCount = Number(product?.reviews_count) ?? 0
  const sku = product.sku
  const canonicalUrl = new URL(product.canonical_url).pathname
  const skus =
    product.configurable_variants?.map((variant) => variant.product.sku) ?? []
  const image = {
    url: {
      small: product.image.url,
    },
    label: product.image.alt,
  }
  const longTermUnavailable = Number(product.long_term_unavailable) ?? null
  const breadcrumbEn = product.breadcrumbs_en
  const productLabels = product.product_labels ?? []

  return {
    name: product.name,
    manufacturerInfo: {
      name: product.manufacturer_info.name,
      pathname: product.manufacturer_info.pathname,
    },
    finalPrice: product.price.final_price,
    priceRange,
    isProductSingleVariant,
    ratingSummary,
    stockStatus,
    thumbnail,
    enabled,
    reviewCount,
    sku,
    singleVariantSku,
    canonicalUrl,
    skus,
    id: product.id,
    image,
    longTermUnavailable,
    breadcrumbEn,
    productLabels,
    isSaleable: product.is_saleable,
    isBestseller: product.is_bestseller === '1',
    configurableVariants: product.configurable_variants ?? [],
    type: getProductListItemType(product.type),
  }
}

export async function getProductsBySKUsClient(
  skus: string[],
): Promise<ProductListItem[]> {
  const searchParams = new URLSearchParams({
    skus: skus.join(','),
  })
  const result = await clientFetch<ProductListItem[]>({
    url: `/api/v1/products-by-skus?${searchParams.toString()}`,
  })

  if (!result) {
    return []
  }

  return result
}

export async function getRecommendedProductsClient(
  input: RecommendedProductsInput,
): Promise<RecommendedProducts> {
  const result = await clientFetch<RecommendedProducts>({
    url: '/api/v1/recommended-products',
    method: 'POST',
    body: input,
  })

  if (!result) {
    return {
      products: [],
      tests: [],
    }
  }

  return result
}
