import Cookies from 'js-cookie'

/** @deprecated - TODO: migrate from CookieKeys to COOKIE */
export enum CookieKeys {
  GAUID = '_ga',
  TEST_VARIANT = 'test_variant',
  TEST_NAME = 'test_name',
  COOKIE_CONSENT = 'CookieConsent',

  // used for authorization
  PHP_SESSION_ID = 'PHPSESSID',

  // used for authentication
  CUSTOMER_TOKEN = 'customerToken', // used and managed by Magento
  NEXT_TOKEN = 'nextToken', // used and managed by Next.js

  // used for customer segment
  CUSTOMER_SEGMENT = 'customerSegment',

  // persistent_quote is a cookie that stores the guest token
  // it is created by magento custom gymbeam module
  PERSISTENT_QUOTE = 'persistent_quote',
}

export const COOKIE = {
  PHP_SESSION_ID: 'PHPSESSID',
  CUSTOMER_TOKEN: 'customerToken',
  NEXT_TOKEN: 'nextToken', // managed by Next.js (TODO: once customerToken works as expected we can remove this token)
  CUSTOMER_SEGMENT: 'customerSegment',
  CUSTOMER_GROUP: 'customerGroup',
  PERSISTENT_QUOTE: 'persistent_quote', // guest token - it is created by magento custom gymbeam module
  COOKIE_CONSENT: 'CookieConsent',
  TEST_VARIANT: 'test_variant',
  TEST_NAME: 'test_name',
  GAUID: '_ga',
} as const

export type CookieType = typeof COOKIE[keyof typeof COOKIE]

export function getAllCookies() {
  return Cookies.get()
}

export function getCookie(key: string) {
  return Cookies.get(key)
}

export function setCookie(
  key: string,
  value: string,
  options?: Cookies.CookieAttributes,
): string | undefined {
  return Cookies.set(key, value, options)
}

export function removeCookie(key: string) {
  Cookies.remove(key)
}
