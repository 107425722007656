import { Domain, StoreCodeType } from '@/common/types'
import { STORE_CODES } from '.'
import { Language } from '../types/language-types'
import {
  getHomepageUrl,
  getUaBlogUrl,
  getUaRuBlogUrl,
} from './store.exceptions'
import { LOCALES } from '../constants'
import { SEARCH_PARAMS } from '../constants/url-constants'

export function convertAbsoluteUrlToRelative(absoluteUrl: URL): string {
  return `${absoluteUrl.pathname}${absoluteUrl.search}${absoluteUrl.hash}`
}

/**
 * Removes locale from pathname
 */
export function getPathnameWithoutLocale(pathname: string): string {
  if (!pathname) {
    return '/'
  }

  const path = pathname.startsWith('/') ? pathname : `/${pathname}`
  const paths = path.split('/') as any[]

  if (LOCALES.includes(paths[1])) {
    paths.splice(1, 1)
    return paths.join('/')
  }

  return path
}

/**
 * Generate URL based on the provided base URL, href, and locale (domain also used as locale)
 *
 * - removes the locale from the href as it's a part of the baseUrl
 * - removes trailing slashes
 * - returns the final URL with search and hash values
 */
export function getUrl(
  storeCode: StoreCodeType,
  baseUrl: string,
  href?: string,
): string {
  if (!href) {
    return baseUrl
  }

  // Note: for some reason, if a price filter is applied the filters come encoded with ?%3F, in postman it works fine
  const cleanedHref = href.replaceAll('?%3F', '?')

  const normalizedUrl = normalizeLocaleUrl(
    new URL(cleanedHref, baseUrl),
    storeCode,
  )
  const { pathname, search, hash } = normalizedUrl
  const protocol = getProtocol(baseUrl)
  const base = baseUrl.replace(protocol, '')

  const searchParams = new URLSearchParams(search)

  // Note: remove ___store param
  searchParams.delete('___store')
  // Note: Remove _rsc param - not sure how they get added into CP filters...
  searchParams.delete('_rsc')

  // Note: dedupe filter=out param
  if (searchParams.has('filter') && searchParams.get('filter') === 'out') {
    searchParams.set('filter', 'out')
  }

  const searchString =
    searchParams.toString().length > 0 ? `?${searchParams.toString()}` : ''

  let pathnameWithoutLocale = getPathnameWithoutLocale(pathname)

  if (pathnameWithoutLocale.endsWith('/')) {
    pathnameWithoutLocale = pathnameWithoutLocale.slice(0, -1)
  }

  let url = handleMultipleSlashInPath(`${base}${pathnameWithoutLocale}`)

  if (pathnameWithoutLocale === '/') {
    url = getHomepageUrl(storeCode, url)
  }

  if (pathnameWithoutLocale === '/blog') {
    url = getUaBlogUrl(storeCode, url)
  }

  if (
    pathnameWithoutLocale === '/blog' ||
    pathnameWithoutLocale === '/blog/uk'
  ) {
    return getUaRuBlogUrl(
      storeCode,
      new URL(`${protocol}${url}${searchString}${hash}`),
    )
  }

  return `${protocol}${url}${searchString}${hash}`
}

export function replaceFirstUaRuLocaleInUrl(url: string): string {
  // Regex pattern to match /ua/something or /ru/something immediately after the URL host
  const pattern = /^(https?:\/\/[^\/]+)\/(ua|ru)(\/[^\/]*)/

  // Replace the matched pattern with the base URL and the rest of the path without /ua or /ru
  const result = url.replace(pattern, '$1$3')

  return result
}

function containsUaInPathname(url: string): boolean {
  if (isAbsoluteUrl(url)) {
    const pattern = /^(https?:\/\/[^\/]+\/)?(ua)(\/[^\/]*)/
    return (
      pattern.test(url) ||
      url.endsWith('/ua') ||
      url.endsWith('/uk') ||
      url.includes('ua.') ||
      url.includes('.ua')
    )
  } else {
    const pattern = /^(\/)?(ua|uk)(\/|$)/
    return pattern.test(url) || url.endsWith('/ua') || url.endsWith('/uk')
  }
}

function containsRuInPathname(url: string): boolean {
  if (isAbsoluteUrl(url)) {
    const pattern = /^(https?:\/\/[^\/]+\/)?(ru)(\/[^\/]*)/
    return pattern.test(url) || url.endsWith('/ru')
  } else {
    const pattern = /^(\/)?(ru)(\/|$)/
    return pattern.test(url) || url.endsWith('/ru')
  }
}

function isAbsoluteUrl(url: string): boolean {
  try {
    new URL(url)
    return true
  } catch (e) {
    return false
  }
}

export function combineUrls(href: string, baseUrl?: string): string {
  if (baseUrl === undefined) {
    return href
  }

  if (isAbsoluteUrl(href) && !isAbsoluteUrl(baseUrl)) {
    return href
  }

  if (isAbsoluteUrl(href) && isAbsoluteUrl(baseUrl)) {
    const diff = new URL(href)
    const mergedUrl = new URL(diff.pathname, baseUrl)
    return mergedUrl.toString()
  }

  if (isAbsoluteUrl(baseUrl) && href !== '/') {
    const mergedUrl = new URL(href, baseUrl)
    return mergedUrl.toString()
  }

  if (isAbsoluteUrl(baseUrl) && href === '/') {
    const base = new URL(baseUrl)
    const isUa = containsUaInPathname(baseUrl)
    const isRu = containsRuInPathname(baseUrl)

    if (isUa) {
      base.pathname = `ua`
      return base.toString()
    }

    if (isRu) {
      base.pathname = `ru`
      return base.toString()
    }

    base.pathname = href
    return base.toString()
  }

  return href.startsWith('/') ? href : `/${href}`
}

export const getOnlyBaseUrl = (url: string): string => {
  if (isAbsoluteUrl(url)) {
    const urlObject = new URL(url)

    if (urlObject.pathname === '/') {
      return urlObject.toString()
    }

    return `${urlObject.origin}/`
  }

  return url
}

export function normalizeUaPath(url: string): string {
  const pattern = /(\/ua|\/ru|\/uk)+\//g
  return url.replace(pattern, '/ua/')
}

export function normalizeRuPath(url: string): string {
  const pattern = /(\/ru|\/ua|\/uk)+\//g
  return url.replace(pattern, '/ru/')
}

export const getURLObjectSafely = (url?: string | null): URL => {
  try {
    return new URL(url ?? '')
  } catch {
    return {
      hash: '',
      host: '',
      hostname: '',
      href: '',
      origin: '',
      password: '',
      pathname: '',
      port: '',
      protocol: '',
      search: '',
      searchParams: new URLSearchParams(''),
      username: '',
      toString: () => '',
      toJSON: () => '',
    }
  }
}

export const handleMultipleSlashInPath = (path: string) =>
  path.replace(/\/{2,}/g, '/')

export const convertToURLSearchParams = (
  params: Record<string, string | string[] | undefined>,
) => {
  const searchParams = new URLSearchParams()

  for (const [key, value] of Object.entries(params)) {
    if (value) {
      Array.isArray(value)
        ? value.forEach((item) => searchParams.append(key, item))
        : searchParams.append(key, value)
    }
  }

  return searchParams
}

/**
 * Removes extra `/` symbols from a complete url and ignores the protocol part,
 * meaning `https://` and `http://` are ignored.
 */
export const normalizeUrl = (url: string) => url.replace(/([^:]\/)\/+/g, '$1')

/**
 * Merges multiple URLSearchParams instances into a single one
 * Latter params override earlier ones with same keys
 */
export function mergeSearchParams(
  ...searchParamsList: (URLSearchParams | string | Record<string, string>)[]
): URLSearchParams {
  const mergedParams = new URLSearchParams()

  searchParamsList.forEach((params) => {
    let searchParams = new URLSearchParams()

    if (params instanceof URLSearchParams) {
      searchParams = params
    } else if (typeof params === 'string') {
      searchParams = new URLSearchParams(params)
    } else {
      searchParams = new URLSearchParams(Object.entries(params))
    }

    for (const [key, value] of searchParams.entries()) {
      mergedParams.set(key, value)
    }
  })

  return mergedParams
}

export function keepOnlyCustomParams(
  searchParams: URLSearchParams,
): URLSearchParams {
  const params = new URLSearchParams()

  searchParams.forEach((value, key) => {
    if (key === SEARCH_PARAMS.CategorySorting) {
      params.append(key, value)
    }
  })

  return params
}

export const isUA = (storeCode: StoreCodeType) => storeCode === STORE_CODES.ua

export const isRU = (storeCode: StoreCodeType) => storeCode === STORE_CODES.ru

export const isUaDomain = (storeCode: StoreCodeType): boolean => {
  return storeCode === STORE_CODES.ua || storeCode === STORE_CODES.ru
}

export const getUrlLocale = (language: Language) => {
  switch (language) {
    case Language.Uk:
      return Domain.UA
    case Language.Ru:
      return Domain.RU

    default:
      return language
  }
}

const getProtocol = (href: string) => {
  if (href.startsWith('https://')) {
    return 'https://'
  }

  if (href.startsWith('http://')) {
    return 'http://'
  }

  return ''
}

export const normalizeLocaleUrl = (url: URL, storeCode: StoreCodeType): URL => {
  const normalizedUrl = new URL(url.toString())

  // Routing - TODO: refactor
  if (storeCode === STORE_CODES.ua || storeCode === STORE_CODES.ru) {
    if (url.pathname.startsWith('/ua') || url.pathname.startsWith('/ru')) {
      const pathname = normalizedUrl.pathname.replace(/^\/(ua|ru)/, '')

      normalizedUrl.pathname = pathname
    }
  }

  return normalizedUrl
}
