import { StoreConfigQuery } from '@/api'
import { Domain, StoreCodeType, StoreConfigType } from '@/common/types'
import { CurrencyType } from '@/api'

export function getBaseUrl(storeCode: StoreCodeType, domain: Domain): string {
  let url = process.env.NEXT_PUBLIC_BASE ?? ''

  if (storeCode === Domain.RU) {
    url = url.replace('<domain>', Domain.UA)
  } else {
    url = url.replace('<domain>', domain)
  }

  if (process.env.NEXT_PUBLIC_BRANCH_NAME) {
    url = url.replace('<branch-name>', process.env.NEXT_PUBLIC_BRANCH_NAME)
  }

  if (storeCode === Domain.UA || storeCode === Domain.RU) {
    url = `${url}/${domain}/`
  }

  return url
}

export const processStoreConfig = (
  storeConfigQuery: StoreConfigQuery,
  storeCode: StoreCodeType,
  domain: Domain,
): StoreConfigType => {
  const {
    dual_currency: dualCurrency,
    cookie_restriction: cookieRestriction,
    default_display_currency_code: currency,
    default_display_currency_symbol: currencySymbol,
    date_format: dateFormat,
    locale,
    website_switcher: websiteSwitcher,
    head_includes: headIncludes,
    head_shortcut_icon: headShortcutIcon,
    default_description: defaultDescription,
    default_title: defaultTitle,
    // base_url: baseUrl, // Issue with UA/RU - PROD - https://gymbeamteam.slack.com/archives/CLY5UN3A8/p1740586119389379
    dognet_s2s: dognetS2s,
  } = storeConfigQuery.storeConfig ?? {}

  return {
    storeCode,
    baseUrl: getBaseUrl(storeCode, domain),
    cookieRestriction: cookieRestriction ?? false,
    currency: (currency as CurrencyType) ?? undefined,
    currencySymbol: currencySymbol ?? undefined,
    dateFormat: dateFormat ?? undefined,
    locale: locale ?? undefined,

    dualCurrency: dualCurrency
      ? { rate: dualCurrency.rate, format: dualCurrency.format }
      : undefined,

    websiteSwitcher: websiteSwitcher,
    headIncludes: headIncludes ?? undefined,
    headShortcutIcon: headShortcutIcon ?? undefined,
    defaultDescription: defaultDescription ?? undefined,
    defaultTitle: defaultTitle ?? undefined,

    dognetS2s: dognetS2s
      ? {
          enabled: dognetS2s?.enabled ?? undefined,
          accountId: dognetS2s?.account_id ?? undefined,
          cookieConsent: dognetS2s?.cookie_consent ?? undefined,
        }
      : undefined,

    facebookAppId: storeConfigQuery.storeConfig?.facebook_app_id ?? undefined,

    ribbon: {
      enabled: storeConfigQuery.storeConfig?.gb_ribbon_enabled || false,
      backgroundColor:
        storeConfigQuery.storeConfig?.gb_ribbon_background_color ?? undefined,
      fontColor:
        storeConfigQuery.storeConfig?.gb_ribbon_font_color ?? undefined,
      message: storeConfigQuery.storeConfig?.gb_ribbon_message ?? '',
      date: storeConfigQuery.storeConfig?.gb_ribbon_date ?? undefined,
    },
  }
}
