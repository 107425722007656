export enum Domain {
  BA = 'ba',
  BG = 'bg',
  COM = 'com',
  CZ = 'cz',
  DE = 'de',
  AT = 'at',
  GR = 'gr',
  HR = 'hr',
  HU = 'hu',
  IT = 'it',
  PL = 'pl',
  RO = 'ro',
  RS = 'rs',
  RU = 'ru',
  SI = 'si',
  SK = 'sk',
  UA = 'ua',
}
