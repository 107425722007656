import {
  COOKIE,
  getCookie,
  removeCookie,
  setCookie,
} from '@/common/utils/cookie-utils'

export const getCustomerSegment = () => {
  const segment = getCookie(COOKIE.CUSTOMER_SEGMENT)

  return segment
}

export const removeCustomerSegment = () => {
  removeCookie(COOKIE.CUSTOMER_SEGMENT)
}

export const setCustomerSegment = (segment: string) => {
  setCookie(COOKIE.CUSTOMER_SEGMENT, segment, { path: '/' })
}
