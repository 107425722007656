import { CategorySearchFilterInput, InputMaybe, SortEnum } from '@/api'
import {
  AvailableCategorySearchFilters,
  CategoryFilter,
} from '@/modules/category/category-types'
import { SortingValues } from '@/modules/category/parts/filters/filters.const'
import { SEARCH_PARAMS } from '@/common/constants/url-constants'
import { getURLObjectSafely } from './url-utils'

export const getActiveFilters = (filters: AvailableCategorySearchFilters) => {
  const activeFilters = filters?.flatMap(
    (filterCategory) =>
      filterCategory?.options
        ?.filter((filter) => filter?.used)
        .map((option) => {
          const filterName =
            filterCategory.type === 'checkbox'
              ? filterCategory.name
              : option?.name

          return {
            ...option,
            filterEventId: filterCategory.global_name,
            name: filterName,
          }
        }) || [],
  )
  return activeFilters
}

export const createSearchParams = (
  searchParams: URLSearchParams,
  code: string,
  value: string,
) => {
  const params = new URLSearchParams(searchParams)

  if (params.has(code)) {
    const values = params.getAll(code)
    if (values.includes(value)) {
      params.delete(code)
      values.forEach((v) => {
        if (v !== value) {
          params.append(code, v)
        }
      })
    } else {
      params.append(code, value)
    }
  } else {
    params.append(code, value)
  }

  return params.toString()
}

export const isFilterActive = (
  searchParams: URLSearchParams,
  code: string,
  value: string,
) => {
  const params = new URLSearchParams(searchParams.toString())

  return params.getAll(code).includes(value)
}

export const removeSearchParam = (
  searchParams: URLSearchParams,
  code: string,
) => {
  const params = new URLSearchParams(searchParams.toString())

  params.delete(code)
  return params.toString()
}

export const removeRangeSearchParam = (params: URLSearchParams) => {
  const newParams = new URLSearchParams(params.toString())
  newParams.delete('price')
  return newParams.toString()
}

export const removeAllFilterParams = (searchParams: URLSearchParams) => {
  const params = new URLSearchParams(searchParams.toString())

  searchParams.forEach((_, key) => {
    params.delete(key)
  })

  return params.toString()
}

export const createFilterInputFromSearchParams = (
  searchParams: URLSearchParams,
) => {
  const filterEntries = searchParams.entries()
  return [...filterEntries].reduce((acc, [key, value]) => {
    if (key === 'price') {
      const [from, to] = value.split('-')
      return {
        ...acc,
        price: { ...acc?.price, from, to },
      }
    } else {
      return {
        ...acc,
        options: [
          ...(acc?.options?.filter((option) => option?.code !== key) || []),
          {
            code: key,
            value: {
              in: acc?.options
                ?.find((option) => option?.code === key)
                ?.value?.in?.concat(value) || [value],
            },
          },
        ],
      }
    }
  }, {} as InputMaybe<CategorySearchFilterInput>)
}

export const filterFirstFiveAndSelectedFilters = (
  filter: CategoryFilter | null,
) =>
  filter?.options
    ?.sort((a, b) => (b?.count ?? 0) - (a?.count ?? 0))
    .slice(0, 5)
    .concat(filter?.options?.slice(5).filter((option) => option?.used))

export const getSortingSearchParams = ({
  value,
  searchParams,
}: {
  value: SortingValues
  searchParams: URLSearchParams
}) => {
  const params = new URLSearchParams(searchParams.toString())

  params.set(SEARCH_PARAMS.CategorySorting, value)

  if (value === SortingValues.ReviewsCount) {
    params.delete(SEARCH_PARAMS.Filter)
  } else {
    params.set(SEARCH_PARAMS.Filter, 'out')
  }

  return params.toString()
}

export const getSortingInput = (href: string) => {
  const url = getURLObjectSafely(href)
  const sortingSearchParamValue =
    url.searchParams.get(SEARCH_PARAMS.CategorySorting) ??
    SortingValues.Bestseller

  switch (sortingSearchParamValue) {
    case SortingValues.PriceAsc:
      return { price: SortEnum.Asc }
    case SortingValues.PriceDesc:
      return { price: SortEnum.Desc }
    case SortingValues.Bestseller:
      return { bestseller: SortEnum.Desc }
    default:
      return { [sortingSearchParamValue]: SortEnum.Desc }
  }
}

export const getCategoryUrl = (href: string) => {
  const url = getURLObjectSafely(href)

  url.searchParams.delete('filter')

  return `${url.pathname}${url.search}`
}
