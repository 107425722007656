import { clientFetch } from '../fetch.client'

export interface GeoLocationData {
  recommended_store: boolean
  description?: string
  redirect_button_title?: string
  stay_button_title?: string
  recommended_stores?: RecommendedStore[]
}

export interface RecommendedStore {
  url: string
  name: string
}

export const getGeoLocation = async () => {
  const response = (await clientFetch({
    url: '/rest/V1/geolocation',
    method: 'POST',
  })) as [GeoLocationData]

  return {
    data: response,
  }
}
