import { z } from 'zod'

import { CookieKeys, getCookie, setCookie } from './cookie-utils'
import { gtmTracker } from '../services/tracking/google/gtm-tracker'
import { StoreCodeType } from '../types'
import { SkeletonType } from '@/components/skeleton/PageSkeleton'

export const isOfType = <T>(
  varToBeChecked: any | undefined | null,
  propertyToCheckFor: keyof T,
): varToBeChecked is T => {
  if (varToBeChecked) {
    return varToBeChecked.hasOwnProperty(propertyToCheckFor)
  }

  return false
}

export const trackABTesting = () => {
  const testCookies = getABTestingCookie(
    getCookie(CookieKeys.TEST_NAME),
    getCookie(CookieKeys.TEST_VARIANT),
  )

  gtmTracker.trackAbTestSetup(
    testCookies.map(({ name, variant }) => ({
      testName: name,
      testVariant: variant,
    })) ?? [],
  )
}

export const delay = (time: number) =>
  new Promise<boolean>((resolve) => {
    setTimeout(() => {
      resolve(true)
    }, time)
  })

// can be used only in the browser
export function setABTestingCookie(
  testing: { name: string; variant: string }[],
) {
  if (testing.length >= 1) {
    setCookie(
      CookieKeys.TEST_NAME,
      testing.map((test) => test.name).join(';'),
      {
        expires: 30,
      },
    )
    setCookie(
      CookieKeys.TEST_VARIANT,
      testing.map((test) => test.variant).join(';'),
      {
        expires: 30,
      },
    )
  }
}

export function getABTestingCookie(
  testNames?: string,
  testVariants?: string,
): { name: string; variant: string }[] {
  if (testNames && testVariants) {
    return testNames.split(';').map((name, index) => ({
      name,
      variant: testVariants.split(';')[index],
    }))
  }

  return []
}

export function convertToJsonString(inputString: string) {
  // Add double quotes to keys
  let validJsonString = decodeURIComponent(inputString).replace(
    /([{,]\s*)([a-zA-Z0-9_]+)(\s*:)/g,
    '$1"$2"$3',
  )

  // Add double quotes to string values (but not to booleans, numbers, or nulls)
  validJsonString = validJsonString.replace(/:\s*'([^']+)'/g, ': "$1"')

  return validJsonString
}

export function getCookieMktConsent(mktConsent?: string): boolean {
  if (mktConsent) {
    const cookieConsent = convertToJsonString(mktConsent)

    try {
      return JSON.parse(cookieConsent).marketing
    } catch {
      return false
    }
  }

  return false
}

export function formatGAUID(gaUID: string) {
  if (gaUID) {
    return gaUID.split('.').slice(-2).join('.')
  }

  return ''
}

export function parseValueFromZodSchema<T extends z.ZodType>(
  obj: unknown,
  schema: T,
): z.infer<T> {
  const parsedResult = schema.safeParse(obj)

  if (!parsedResult.success) {
    throw new Error(`Parsing failed: ${parsedResult.error.message}`)
  }

  return parsedResult.data
}

export function getGraphqlApi() {
  return IS_DEV ? '/api' : ''
}

export const IS_LOCAL = process.env.NEXT_PUBLIC_ENVIRONMENT === 'local'
export const IS_BRANCH = process.env.NEXT_PUBLIC_ENVIRONMENT === 'branch'
export const IS_DEV = IS_LOCAL || IS_BRANCH
export const IS_PROD = process.env.NEXT_PUBLIC_ENVIRONMENT === 'prod'

export const IS_NEXT_AUTH = process.env.NEXT_PUBLIC_AUTH === '1'
export const IS_DEBUG_MODE = process.env.NEXT_PUBLIC_DEBUG === '1'
export const CP_ENABLED_FOR = process.env.NEXT_PUBLIC_CP ?? ''
export const IS_BLOG_ENABLED = process.env.NEXT_PUBLIC_BLOG === '1'
export const IS_MANUFACTURERS_ENABLED =
  process.env.NEXT_PUBLIC_MANUFACTURERS === '1'
export const IS_CONTENT_ENABLED = process.env.NEXT_PUBLIC_CONTENT === '1'

export const getIsCpEnabled = (store: StoreCodeType) => {
  const isEnabled =
    CP_ENABLED_FOR === '1' || CP_ENABLED_FOR.indexOf(store) !== -1

  return isEnabled
}

export const getIsExternal = (store: StoreCodeType, url: string | URL) => {
  const { pathname } = new URL(url, 'http://localhost:3000') // serves only to check the pathname

  const isProductPage = pathname.endsWith('.html')
  const isHomepage = pathname === '/'

  if (isHomepage || isProductPage) {
    return false
  }

  const isContentPage = pathname.startsWith('/content/') && IS_CONTENT_ENABLED

  if (isContentPage) {
    return false
  }

  const isManufacturersPage =
    (pathname.startsWith('/manufacturer/') || pathname === '/manufacturers') &&
    !IS_MANUFACTURERS_ENABLED
  const isBlogPage =
    (pathname.startsWith('/blog') || pathname.startsWith('/ua/blog')) &&
    !IS_BLOG_ENABLED

  if (isManufacturersPage || isBlogPage) {
    return true
  }

  const isCategoryPage = getIsCpEnabled(store)

  return !isCategoryPage
}

export const getSkeletonTarget = (
  store: StoreCodeType,
  url: string | URL,
): SkeletonType => {
  const { pathname } = new URL(url, 'http://localhost:3000') // serves only to check the pathname
  const isExternal = getIsExternal(store, pathname)

  if (isExternal) {
    return ''
  }

  const isHomepage = pathname === '/'

  if (isHomepage) {
    return 'homepage'
  }

  const isProductPage = pathname.endsWith('.html')

  if (isProductPage) {
    return 'product'
  }

  const isCategoryPage = getIsCpEnabled(store)

  if (isCategoryPage) {
    return 'category'
  }

  return ''
}

export const isAccountUrl = (pathname: string) => {
  const isAccountUrl = [
    '/sales/',
    '/vault/',
    '/giftcard/',
    '/review/',
    '/newsletter/',
    '/quickorder',
    '/xnotif/',
  ].find((path) => {
    return pathname.startsWith(path)
  })

  if (isAccountUrl) {
    return true
  }

  const isAccountPage = pathname.startsWith('/customer/')
  const isAccountPublicPage = [
    '/customer/account/login',
    '/customer/account/create',
    '/customer/account/forgotpassword',
    '/customer/account/logout',
    '/customer/account/logoutSuccess',
  ].find((path) => {
    return pathname.startsWith(path)
  })

  if (isAccountPage && isAccountPublicPage) {
    return false
  }

  if (isAccountPage) {
    return true
  }

  return false
}

type Success<T> = {
  data: T
  error: null
}
type Failure<E> = {
  data: null
  error: E
}
type Result<T, E = Error> = Success<T> | Failure<E>

export async function tryCatch<T, E = Error>(
  promise: Promise<T>,
): Promise<Result<T, E>> {
  try {
    const data = await promise
    return { data, error: null }
  } catch (error) {
    return { data: null, error: error as E }
  }
}

const BOT_UA_RE =
  /Googlebot|Mediapartners-Google|AdsBot-Google|googleweblight|Storebot-Google|Google-PageRenderer|Bingbot|BingPreview|Slurp|DuckDuckBot|baiduspider|yandex|sogou|LinkedInBot|bitlybot|tumblr|vkShare|quora link preview|facebookexternalhit|facebookcatalog|Twitterbot|Applebot|redditbot|Slackbot|Discordbot|WhatsApp|SkypeUriPreview|ia_archiver|AhrefsBot|SemrushBot|Lighthouse|Nessus|cURL|Python|Headless|OkHttp|Adbeat|Comscore|Proximic|PetalBot|Pingdom|YandexBot|bot\.html|Barkrowler|GoogleOther|crawler|GPTBot|ChatGPT|Bytespider|CCBot|SearchBot|DiffBot|DuckAssistBot|FacebookBot|AI2Bot|Amazonbot|Anthropic|ClaudeBot|Timpibot|YouBot|PerplexityBot|omgili|Google-Extended|claude-web|contentkingapp/i

export function isBot(userAgent: string): boolean {
  return BOT_UA_RE.test(userAgent)
}
