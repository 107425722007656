'use client'

import {
  GaEvent,
  GaProductInfo,
  EcommerceDetailEvent,
  GaEventType,
  TrackingPage,
  ProductListViewPlacement,
} from './gtm-types'
import { CurrencyEnum } from '@/api'
import { removeUndefinedValuesFromObject } from '@/common/utils/object-utils'
import { roundPrice } from '@/common/utils/price-utils'

declare global {
  interface Window {
    dataLayer?: GaEvent[]
  }
}

export type RecommendedProductTrackingOptions = {
  prodlistPlacement?: ProductListViewPlacement
  productIds?: number[]
  // count from 1
  productPosition?: number
}

export class GtmTracker {
  private previousPage: string | null = null

  trackPage = (pageType: TrackingPage, categories: string[] | string) => {
    const categoriesData: { [key: string]: string } = {}

    if (Array.isArray(categories)) {
      categories.forEach((category, index) => {
        categoriesData[`pageCategory${index + 1}`] = category
      })
    } else {
      categoriesData.pageCategory1 = categories
    }

    gtmTracker.trackGtmEvent({
      pageType,
      ...categoriesData,
    })
  }

  trackRibbonClick = () => {
    gtmTracker.trackGtmEvent({
      event: GaEventType.Base,
      gaEventData: {
        eCat: 'Header bar',
        eAct: 'Click',
        eLab: 'Sale',
      },
      'gtm.uniqueEventId': 40,
    })
  }

  trackCategoryClick = (position: number) => {
    gtmTracker.trackGtmEvent({
      event: GaEventType.Base,
      gaEventData: {
        eCat: 'HP categories',
        eAct: 'Click',
        eLab: `B${position}`,
      },
      'gtm.uniqueEventId': 38,
    })
  }

  trackBannerClick = (position: number) => {
    gtmTracker.trackGtmEvent({
      event: GaEventType.Base,
      gaEventData: {
        eCat: 'HP banner',
        eAct: 'Click',
        eLab: `S${position}`,
      },
      'gtm.uniqueEventId': 36,
    })
  }

  trackRecommendedProductGtmEvent = ({ label }: { label: string }) => {
    this.trackGtmEvent({
      event: GaEventType.Base,
      gaEventData: {
        eCat: 'Recommendation',
        eAct: 'Click',
        eLab: label,
      },
    })
  }

  trackProductVariantsDetail = ({
    currency,
    price,
    productData,
    simpleId,
  }: {
    productData: GaProductInfo
    price: number
    currency: string
    simpleId: string | undefined
  }) => {
    gtmTracker.trackEcommerceDetails({
      currencyCode: currency,
      products: [
        {
          ...productData,
          id: String(productData.id),
          simpleId,
          price: price,
        },
      ],
    })
    this.trackGtmEvent({
      event: GaEventType.TriggerGroup,
      'gtm.triggers': '30929895_194',
      'gtm.uniqueEventId': 10,
    })
  }

  trackEcommerceDetails = ({
    currencyCode,
    products,
  }: {
    currencyCode: string
    products: (GaProductInfo & {
      price: number
      simpleId: string | undefined
    })[]
  }) => {
    this.trackGtmEvent({
      event: GaEventType.ProductVariantsDetail,
      ecommerce: {
        currencyCode,
        detail: {
          products: products.map((product) => ({
            name: product.name,
            id: product.id,
            price: roundPrice(product.price),
            brand: product.brand,
            category: product.category,
            simpleId: product.simpleId,
          })),
        },
      },
    })
  }

  trackAddProductToCart = ({
    currency,
    price,
    productData,
    quantity,
    simpleId,
    recommendedOptions,
  }: {
    productData: GaProductInfo
    quantity: number
    price: number
    currency: CurrencyEnum
    simpleId: number
    recommendedOptions?: RecommendedProductTrackingOptions
  }) => {
    this.trackGtmEvent({
      event: GaEventType.AddToCart,
      prodlist_placement: recommendedOptions?.prodlistPlacement,
      prodlist_ids: recommendedOptions?.productIds?.join(','),
      prodlist_position: recommendedOptions?.productPosition,
      ecommerce: {
        currencyCode: currency || '',
        add: {
          products: [
            {
              name: productData.name,
              id: String(productData.id),
              price: roundPrice(price),
              brand: productData.brand,
              category: productData.category,
              quantity: String(quantity) ?? '1',
              simpleId: String(simpleId),
            },
          ],
        },
      },
    })
  }

  trackRemarketingCategory = ({ productIds }: { productIds: number[] }) => {
    this.trackGtmEvent({
      event: GaEventType.SetupRemarketing,
      google_tag_params: {
        ecomm_pagetype: 'category',
        ecomm_prodid: productIds,
      },
    })
  }

  trackRemarketingProductData = ({
    productId,
    price,
  }: {
    productId: number
    price: number
  }) => {
    this.trackGtmEvent({
      event: GaEventType.SetupRemarketing,
      google_tag_params: {
        ecomm_pagetype: 'product',
        ecomm_prodid: productId,
        ecomm_totalvalue: price,
      },
    })
  }

  trackRecommendedProductAddToCart = () => {
    this.trackRecommendedProductGtmEvent({ label: 'Add to cart' })
  }

  trackRecommendedProductClick = () => {
    this.trackRecommendedProductGtmEvent({ label: 'Product detail' })
  }

  trackProductListView = ({
    placement,
    productIds,
    prodlistViewId,
  }: {
    productIds: string[]
    placement: ProductListViewPlacement
    prodlistViewId: string
  }) => {
    this.trackGtmEvent({
      event: GaEventType.ProductListView,
      prodlist_ids: productIds.join(','),
      prodlist_placement: placement,
      prodlist_view_id: prodlistViewId,
    })
  }

  trackProductListItemClick = (payload: {
    productIds: number[]
    clickedItemPosition: number
    placement: ProductListViewPlacement
  }) => {
    this.trackGtmEvent({
      event: GaEventType.ProductListItemClick,
      prodlist_ids: payload.productIds.join(','),
      prodlist_placement: payload.placement,
      // count starts from 1
      prodlist_position: payload.clickedItemPosition,
    })
  }

  trackAbTestSetup = (tests: { testName: string; testVariant: string }[]) => {
    const formattedTests: Record<string, string> = tests.reduce(
      (acc, curr) => ({
        ...acc,
        [curr.testName]: curr.testVariant,
      }),
      {},
    )

    this.trackGtmEvent({
      event: GaEventType.AbTestSetup,
      ...formattedTests,
    })
  }

  trackUserInfo = ({
    userId,
    userType = 'General',
  }: {
    userId?: number
    userType?: string
  }) => {
    this.trackGtmEvent({
      event: GaEventType.UserInfo,
      'user.Type': userType,
      ...(userId ? { 'user.id': userId } : {}),
      'gtm.uniqueEventId': 1,
    })
  }

  trackUserDataSetup = (userData: {
    email?: string
    firstName?: string
    lastName?: string
    phone?: string
    city?: string
    postalCode?: string
    country?: string
  }) => {
    const userDataDefined = removeUndefinedValuesFromObject(userData)

    this.trackGtmEvent({
      event: GaEventType.UserDataSetup,
      userData: userDataDefined,
    })
  }

  trackSelectedSimpleVariantChange = ({ simpleId }: { simpleId: string }) => {
    if (window.dataLayer) {
      const ecommerceDetailEvent = window.dataLayer.find(
        (trackedEvent) =>
          trackedEvent.event === GaEventType.ProductVariantsDetail,
      ) as EcommerceDetailEvent | undefined

      if (ecommerceDetailEvent) {
        ecommerceDetailEvent.ecommerce.detail.products[0].simpleId = simpleId
      }
    }
  }

  /*
    This is a little hacky way to enforce right order of events in the data layer array. Old gymbeam web page send tracking data from server in custom script tags.
    Thanks to this it pushes some events to dataLayer even before gtm.js event is pushed. However, gtm.js can be used to trigger some tasks in GTM so we need to enforce
    right order after product and user info is already added to dataLayer. By default, this tracking is done by GTM initialization script. However, then it is the first event in the dataLayer
    as initialization script is executed as soon as possible.
  */
  trackGtmStart = () => {
    this.trackGtmEvent({
      'gtm.start': new Date().getTime(),
      event: GaEventType.Start,
    })
    this.trackGtmEvent({
      event: GaEventType.Spa,
      isSPA: 'yes',
      ecommerce: undefined,
      pageType: undefined,
      pageCategory1: undefined,
      pageCategory2: undefined,
      pageCategory3: undefined,
      pageCategory4: undefined,
      google_tag_params: undefined,
    })
  }

  trackVirtualPageView = (pathname: string) => {
    this.trackGtmEvent({
      event: GaEventType.VirtualPageview,
      virtualPV: {
        previousUrl: this.previousPage ?? '',
        currentUrl: pathname ?? window.location.href,
        currentTitle: document.title,
      },
    })
    this.previousPage = pathname
  }

  trackFiltersShown = () => {
    this.trackGtmEvent({
      event: GaEventType.Base,
      gaEventData: {
        eCat: 'Filter',
        eAct: 'Action',
        eLab: 'Show filter',
      },
      'gtm.uniqueEventId': 32,
    })
  }

  trackFiltersHidden = () => {
    this.trackGtmEvent({
      event: GaEventType.Base,
      gaEventData: {
        eCat: 'Filter',
        eAct: 'Action',
        eLab: 'Hide filter',
      },
      'gtm.uniqueEventId': 249,
    })
  }

  trackFilter = (filterId: string) => {
    this.trackGtmEvent({
      event: GaEventType.Base,
      gaEventData: {
        eCat: 'Filter',
        eAct: 'Filter by',
        eLab: filterId,
      },
      'gtm.uniqueEventId': 35,
    })
  }

  trackGtmEvent = (data: GaEvent) => {
    if (Array.isArray(window.dataLayer)) {
      window.dataLayer.push(data)
    } else {
      window.dataLayer = [data]
    }
  }
}

export const gtmTracker = new GtmTracker()
