import { getWholesalePercentage } from '../get-wholesale-percentage'
import { ProductPriceData, ProductPriceInputData } from './get-price.types'

export function getProductPrice(
  productPrice?: ProductPriceInputData | null,
): ProductPriceData {
  if (!productPrice) {
    return {
      showRegularPrice: false,
      finalPrice: {
        value: 0,
        currency: 'EUR',
      },
      recommendedPrice: {
        value: 0,
        currency: 'EUR',
      },
      allFinalPricesAreEqual: true,
      wholesaleDiscountPercentage: 0,
      wholesaleDiscountPercentageFormatted: '0%',
    }
  }

  const maxFinalPrice =
    productPrice.price_range?.maximum_price?.final_price?.value ?? 0
  const maxFinalPriceExclTax =
    productPrice.price_range?.maximum_price?.final_price?.value_excl_tax

  const maxRegularPrice =
    productPrice.price_range?.maximum_price?.regular_price?.value ?? 0
  const maxRegularPriceExclTax =
    productPrice.price_range?.maximum_price?.regular_price?.value_excl_tax

  const minFinalPrice =
    productPrice.price_range?.minimum_price?.final_price?.value ?? 0
  const currency =
    productPrice.price_range?.maximum_price?.regular_price.currency ?? 'EUR'

  const msrpPrice = productPrice.msrp_price ?? 0

  const showRegularPrice = maxRegularPrice > maxFinalPrice

  const { wholesaleDiscountPercentage, wholesaleDiscountPercentageFormatted } =
    getWholesalePercentage(maxRegularPrice, maxFinalPrice)

  return {
    showRegularPrice,
    finalPrice: {
      value_excl_tax: maxFinalPriceExclTax,
      value: maxFinalPrice,
      currency: currency,
    },
    recommendedPrice: {
      value_excl_tax: showRegularPrice ? maxRegularPriceExclTax : undefined,
      value: showRegularPrice ? maxRegularPrice : msrpPrice,
      currency: currency,
    },
    allFinalPricesAreEqual: minFinalPrice === maxFinalPrice,
    wholesaleDiscountPercentage,
    wholesaleDiscountPercentageFormatted,
  }
}

export const arePricesDifferentInVariants = (
  prices: (number | undefined | null)[],
) => {
  const uniquePrices = new Set(prices.filter(Boolean))

  return uniquePrices.size > 1
}
