import { clientFetch } from '../fetch.client'

// interface PostReviewRateData {
//   checked: number
//   checked_at: string
//   checked_by: number
//   country_code: string
//   country_flag: string
//   created_at: string
//   customer_avatar: string
//   customer_id: number
//   customer_rank: string | number | null
//   detail: string
//   id: number
//   is_translated: boolean
//   nickname: string
//   rate_minus: number
//   rate_plus: number
//   rate_sum: number
//   review_id: number
//   status_id: number
//   summary_rating: number
//   title: string
//   verified: boolean
// }

// TODO: use { data: PostReviewRateData } intead of any
export const postReviewRate = (
  type: 'plus' | 'minus',
  reviewId: string | number,
) =>
  clientFetch<any>({
    url: `/rest/V1/review/${reviewId}/rate/${type}`,
    method: 'POST',
  })
