import {
  reviewFilters,
  ReviewsFilterType,
} from '@/modules/product/components/tabs/components/reviews/filter/filter-types'
import {
  productQuestionPaginationFragment,
  productReviewPaginationFragment,
  SEARCH_PARAMS,
} from '../constants/url-constants'
import { SortingValues } from '@/modules/category/parts/filters/filters.const'
import { getURLObjectSafely } from './url-utils'

export type ProcessedUrlData = {
  pathname: string
  search: string
  reviewCurrentPage: number
  questionsCurrentPage: number
  reviewPaginationUsed: boolean
  isReviewsCountSorting: boolean
  questionPaginationUsed: boolean
  reviewFilter: ReviewsFilterType
}

const getReviewFilter = (searchParams: URLSearchParams): ReviewsFilterType => {
  const type = searchParams.get('type')

  if (type && reviewFilters.includes(type as ReviewsFilterType)) {
    return type as ReviewsFilterType
  }

  return ReviewsFilterType.None
}

/**
 * function processes the URL, because in some cases, url includes additional information such as the active tab and the current page.
 * e.g.: /290-100-whey-gold-standard-protein-optimum-nutrition.html?review-page=3
 * @param requestUrl
 */
export const processRequestUrl = (requestUrl: string): ProcessedUrlData => {
  const url = getURLObjectSafely(requestUrl)

  const pathname = parsePathnameFromUrl(url)
  const searchParams = url.searchParams
  const reviewFilter = getReviewFilter(searchParams)
  const reviewPaginationUsed =
    !!searchParams.get(productReviewPaginationFragment) ||
    !!searchParams.get('type')
  const reviewCurrentPage = Number(
    searchParams.get(productReviewPaginationFragment) ?? '1',
  )
  const questionPaginationUsed = !!searchParams.get(
    productQuestionPaginationFragment,
  )
  const questionsCurrentPage = Number(
    searchParams.get(productQuestionPaginationFragment) ?? '1',
  )
  const isReviewsCountSorting =
    searchParams.get(SEARCH_PARAMS.CategorySorting) ===
    SortingValues.ReviewsCount

  return {
    pathname,
    search: url.search,
    reviewFilter,
    reviewCurrentPage,
    questionsCurrentPage,
    reviewPaginationUsed,
    isReviewsCountSorting,
    questionPaginationUsed,
  }
}

// TODO: unit test
export function parsePathnameFromUrl(url: URL): string {
  const rawPathname = url.pathname

  return rawPathname.substring(rawPathname.lastIndexOf('/'))
}
